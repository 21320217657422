import React from "react"
import useStory from "hooks/useStory"
import Page from "components/_common/Page"
import BlogHeader from "components/_pages/blog-post/BlogHeader"
import BlogContent from "components/_pages/blog-post/BlogContent"
import BlogFooter from "components/_pages/blog-post/BlogFooter"
import BlogRelatedPosts from "components/_pages/blog-post/BlogRelatedPosts"
import { ROUTES } from "consts"

export default function BlogPostTemplate({ pageContext }) {
  const { story: pageStory, layout: layoutStory } = pageContext

  const [story, layout] = useStory(pageStory, layoutStory)

  const {
    body,
    tags,
    related_posts,
    seo_data,
    featured_image,
    title,
    introduction,
  } = story

  // TODO: Refactor - reuse same SEO object across pages (this is the only page with different SEO object format)
  const pageStoryProps = {
    seo_title: seo_data?.title || title,
    seo_description: seo_data?.description || introduction,
    seo_cover: featured_image,
    ...story,
    seo_data: null,
  }
  const slug = `${ROUTES.BLOG_PRESLASHLESS}/${pageContext.story.slug}`

  return (
    <Page
      story={pageStoryProps}
      {...{ layout, slug }}
      layoutProps={{
        whiteBackground: true,
      }}
      langLinks={pageContext.langLinks}
    >
      <BlogHeader blok={story} slug={slug} />
      <BlogContent content={body} />
      <BlogFooter tags={tags} />
      <BlogRelatedPosts posts={related_posts} />
    </Page>
  )
}
