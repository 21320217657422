import React from "react"
import { useIntl } from "gatsby-plugin-intl"
import { isValidArray, formatDateIntl } from "helpers"
import Link from "components/_common/Link"
import Wrapper from "components/_common/Wrapper"
import BlogIntroduction from "components/_common/BlogIntroduction"
import Img from "components/_common/Image"
import s from "./blogRelatedPosts.module.scss"

export default function BlogRelatedPosts({ posts }) {
  const intl = useIntl()

  return (
    <Wrapper renderAs="aside" withoutDefaultPaddings>
      <h3 className={s.sectionHeading}>
        {intl.formatMessage({
          id: "Related posts",
        })}
      </h3>
      <div className={s.container}>
        {isValidArray(posts) &&
          posts.map((relatedPost, i) => {
            const { content, full_slug } = relatedPost
            return (
              <Column key={`column-${i}`} blok={content} link={full_slug} />
            )
          })}
      </div>
    </Wrapper>
  )
}

function Column({ blok, link }) {
  const { featured_image, title, introduction, date_published } = blok
  const intl = useIntl()

  return (
    <Link to={link} className={s.col}>
      <Img
        src={featured_image}
        options={{
          maxWidth: 350,
        }}
        className={s.featuredImage}
        fluid
      />
      <h3>{title}</h3>
      <time className={s.date}>{formatDateIntl(date_published, intl)}</time>
      <BlogIntroduction introduction={introduction} />
    </Link>
  )
}
